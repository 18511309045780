.p-dialog-titlebar {
  display: none !important;
}

.p-dialog-footer {
  display: none !important;
}

.modal-signup-text {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #2b2d6b;
}
